body {
  font-family: Arial, sans-serif;
  background-color: #ffffff;
  padding: 20px;
}

.addgift-main {
  width: 100%;
  margin: 0 auto;
  max-width: 662px;
}

.heading-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heading-divcontent h1 {
  letter-spacing: 0.46515px;
  font-family: Guardian-EgypTT, Charter, Charter Bitstream, Cambria,
    Noto Serif Light, Droid Serif, Georgia, "serif";
  font-size: 31.01px;
  font-weight: 300;
  line-height: 1.1;
  margin-bottom: 0;
}

.heading-divcontent p {
  font-family: Graphik Webfont, -apple-system, Helvetica Neue, Droid Sans, Arial,
    "sans-serif";
  font-size: 12.99px;
  font-weight: 400;
  line-height: 1.4;
  color: #595959;
  margin: 3px 0 10px 0;
}

.heading-divbtn button {
  color: #222;
  border-color: transparent;
  background-color: rgba(14, 14, 14, 0.09);
  padding: 8px 16px;
  font-size: 12px;
  line-height: 1.4;
  font-weight: 600;
  border-radius: 25px;
}

.heading-divbtn button:hover {
  color: #222;
  border-color: transparent;
  background-color: rgba(14, 14, 14, 0.18);
}

.form-label {
  margin-bottom: 0.5rem;
  font-weight: 600;
  font-size: 16px;
}

.form-control {
  padding: 9px 12px;
  background-color: #ffffff;
  height: 48px;
  font-size: 14px;
}

div#emailHelp {
  float: right;
}

hr.hr1 {
  margin: 25px 0px 30px 0px;
  color: #8f9193;
}

.label-div {
  display: flex;
  flex-direction: column;
}

.label-div .form-label {
  margin-bottom: 0.1rem;
}

.label-div p {
  font-family: Graphik Webfont, -apple-system, Helvetica Neue, Droid Sans, Arial,
    "sans-serif";
  font-size: 12.99px;
  font-weight: 400;
  line-height: 1.4;
  color: #595959;
  margin: 0px 0 5px 0;
}

.teaser-container {
  display: flex;
  gap: 30px;
  padding: 20px;
  border: 1px solid #dee2e6;
  border-radius: 15px;
}

.teaser-optiondiv.selected .teaser-option {
  border: 2px solid #000;
  box-shadow: inset 0 0 0 4px #fff;
}

.teaser-preview {
  word-break: break-word;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 7px solid #000;
  border-radius: 24px;
  max-width: 375px;
  margin: 0;
}

.teaser-image {
  width: 100px;
  margin-bottom: 20px;
}

.teaser-options {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  max-width: 400px;
}

.teaser-option {
  width: 100px;
  height: 150px;
  padding: 10px;
  text-align: center;
  border-radius: 8px;
}

.option-image {
  width: 100%;
  object-fit: cover;
  padding-top: 20px;
}

.teaser-message {
  letter-spacing: -0.6202px;
  font-family: Graphik Webfont, -apple-system, Helvetica Neue, Droid Sans, Arial,
    "sans-serif";
  font-size: 27px;
  font-weight: 400;
  line-height: 1.1;
  color: #fff;
}

.teaser-details {
  letter-spacing: 0.1299px;
  font-family: Graphik Webfont, -apple-system, Helvetica Neue, Droid Sans, Arial,
    "sans-serif";
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  color: #fff;
}

.teaser-message span {
  font-style: italic;
  letter-spacing: -0.6202px;
  font-family: Graphik Webfont, -apple-system, Helvetica Neue, Droid Sans, Arial,
    "sans-serif";
  font-size: 27px;
  font-weight: 400;
  line-height: 1.1;
  color: #fff;
}

.teaser-card {
  display: flex;
  gap: 50px;
  align-items: center;
}

.teaser-optiondiv {
  display: flex;
  flex-direction: column;
}

img.product-img {
  max-width: 315px;
  border-radius: 15px;
  object-fit: cover;
  width: 100%;
  filter: blur(5px);
  transform: scale(1.1);
}

.teaser-topdiv {
  aspect-ratio: 3 / 3;
  align-items: flex-start;
  padding: 18px;
  position: relative;
  overflow: hidden;
  background: #f5f5f5 !important;
  border-radius: 15px;
}

.product-imgdiv {
  border-radius: 12px;
  overflow: hidden;
}

.teaser-optiondiv p {
  font-family: Graphik Webfont, -apple-system, Helvetica Neue, Droid Sans, Arial,
    "sans-serif";
  font-size: 12.99px;
  font-weight: 400;
  line-height: 1.4;
  color: #595959;
  margin: 3px 0 10px 0;
}

.teaser-options-bottom {
  scrollbar-width: none;
  max-height: 385px !important;
  overflow: auto !important;
}

.record-container {
  display: flex;
  gap: 30px;
  padding: 20px;
  border: 1px solid #dee2e6;
  border-radius: 15px;
  margin-top: 30px;
}

.record-topdiv {
  padding: 18px;
  background: #f5f5f5 !important;
  border-radius: 15px;
  max-width: 50%;
}

.record-imgdiv {
  display: flex;
  gap: 35px;
  align-items: center;
  justify-content: center;
  padding: 5px 0px 30px 0px;
}

.record-imgdiv img {
  width: 50px;
}

.record-topdiv p {
  font-family: Graphik Webfont, -apple-system, Helvetica Neue, Droid Sans, Arial,
    "sans-serif";
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  padding: 0px 35px;
  margin-bottom: 0;
  text-align: center;
}

.record-options-main label {
  display: block;
  margin-bottom: 110px;
  text-align: center;
}


.record-btndiv {
  display: flex;
  gap: 20px;
}

.record-btndiv svg {
  width: 20px;
  margin-right: 5px;
}

.record-btndiv button {
  padding: 5px 28px;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 500;
  background: 0 0;
  min-width: 164px;
}

.ckeckbox-main {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 30px;
}

.checkbox-content {
  display: flex;
  flex-direction: column;
  line-height: 18px;
}

.checkbox-content span {
  font-family: Graphik Webfont, -apple-system, Helvetica Neue, Droid Sans, Arial,
    "sans-serif";
  font-size: 12.99px;
  font-weight: 400;
  line-height: 1.4;
  color: #595959;
}

.ckeckbox-main input {
  width: 30px;
  min-width: 30px;
  height: 30px;
}

.select-maindiv {
  margin-top: 30px;
}

.select-maindiv .form-select {
  padding: 9px 12px;
  background-color: #ffffff;
  height: 48px;
  font-size: 14px;
}

.specific-datediv p {
  font-family: Graphik Webfont, -apple-system, Helvetica Neue, Droid Sans, Arial,
    "sans-serif";
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  color: #595959;
  margin: 0px 0 10px 0;
}

.specific-datediv {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.specific-datediv1 label {
  margin-bottom: 0;
}

.purchase-link {
  margin-top: 12px;
  margin-bottom: 0;
}

.save-btn {
  padding: 10px 15px;
  border-radius: 25px;

}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.Policy-div {
  letter-spacing: 0.1299px;
  font-family: Graphik Webfont, -apple-system, Helvetica Neue, Droid Sans, Arial,
    "sans-serif";
  font-size: 12.99px;
  font-weight: 400;
  line-height: 1.4;
  margin-top: 30px;
}

.Policy-div a {
  letter-spacing: 0.1299px;
  font-family: Graphik Webfont, -apple-system, Helvetica Neue, Droid Sans, Arial,
    "sans-serif";
  font-size: 12.99px;
  font-weight: 400;
  line-height: 1.4;
  color: #252525;
  margin-left: 5px;
}

/* Modal backdrop */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

/* Modal container */
.modal-container {
  background-color: white;
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
  position: relative;
  max-width: 725px;
  max-height: 700px;
  overflow: auto;
}

.modal-content1 {
  padding: 35px 20px 0px 20px;
  background: #e9e9e9;
  border-radius: 15px;

}

.modal-content-microphone {
  padding: 35px 20px 0px 20px;
  background: #e9e9e9;
  border-radius: 15px;

}

.modal-content p {
  font-family: Graphik Webfont, -apple-system, Helvetica Neue, Droid Sans, Arial, "sans-serif";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  margin: 20px 0px;
  text-transform: capitalize;
}

.icon-section img {
  width: 50px;
}

/* Close button */
.close-btn {
  cursor: pointer;
  background: none;
  border: none;
  font-size: 38px;
  color: #fff;
}


.close-btndiv button.close-btn {
  color: #303030;

}

.close-btndiv1 button.close-btn {
  color: #303030;
}


.close-btndiv {
  position: relative;
  border: none;
  font-size: 20px;
  z-index: 9;
  text-align: end;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.close-btndiv1 {
  position: relative;
  border: none;
  font-size: 20px;
  z-index: 9;
  text-align: end;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: end;
}




/* Modal content */
.modal-content h2 {
  font-size: 18px;
  margin: 20px 0;
}

/* Icon section */
.icon-section {
  display: flex;
  justify-content: center;
  gap: 35px;
  margin-bottom: 20px;
}

/* Buttons */
.button-section {
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
}

.button-section svg {
  width: 20px;
  margin-right: 5px;
}

.upload-btn {
  padding: 10px 20px;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 500;
  background: 0 0;
  cursor: pointer;
}

.modal-content1 h2 {
  letter-spacing: 0.08px;
  font-family: Graphik Webfont, -apple-system, Helvetica Neue, Droid Sans, Arial,
    "sans-serif";
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  padding-top: 20px;
}

.modal-content-microphone h2 {
  letter-spacing: 0.08px;
  font-family: Graphik Webfont, -apple-system, Helvetica Neue, Droid Sans, Arial,
    "sans-serif";
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  padding-top: 20px;
}

.record-btn {
  padding: 10px 20px;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

button.btn.btn-dark.record-btn svg {
  fill: #ffff;
}

.modal-container1 {
  background-color: white;
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
  position: relative;
  max-width: 350px;
}

.modal-container-microphone {
  background-color: white;
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  text-align: center;
  position: relative;
  max-width: 350px;
}

.icon-section.sec-icon img {
  width: 80px;
}

.upload-btn1 {
  padding: 10px 30px;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 500;
  background: 0 0;
  cursor: pointer;
}

.record-btn1 {
  padding: 10px 30px;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.button-section1 {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.button-section1 svg {
  width: 20px;
  margin-right: 5px;
}

button.btn.btn-dark.record-btn1 svg {
  fill: #ffff;
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .addgift-main {
    width: 100%;
  }

  .teaser-container {
    flex-direction: column;
  }

  .teaser-topdiv {
    max-height: 450px;
  }

  .teaser-preview {
    margin: 75px auto 0 auto !important;
  }

  .teaser-options {
    flex-wrap: unset;
    max-width: unset;
    overflow: auto;
  }

  .record-topdiv p {
    padding: 0px 15px;
  }

  .record-btndiv button {
    padding: 5px 20px;
  }


  .close-btndiv {
    top: 0;
    left: 0;
    z-index: 10;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .modal-backdrop {
    padding: 10px;
  }

  .addgift-main {
    width: 100%;
  }

  .teaser-container {
    flex-direction: column;
  }

  .teaser-topdiv {
    max-height: 450px;
  }

  .teaser-preview {
    margin: 75px auto 0 auto !important;
  }

  .teaser-options {
    flex-wrap: unset;
    max-width: unset;
    overflow: auto;
  }

  .record-topdiv p {
    padding: 0px 15px;
  }

  .record-btndiv button {
    padding: 5px 20px;
  }



  .close-btndiv {
    top: 0;
    left: 0;
    z-index: 10;
  }
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {

  .record-options-main label {
    display: block;
    margin-bottom: 30px;
    text-align: center;
  }

  .modal-backdrop {
    padding: 10px;
  }

  audio {
    width: 100%;
    margin-bottom: 10px;
  }

  .addgift-main {
    width: 100%;
  }

  .teaser-container {
    flex-direction: column;
    padding: 10px;
  }

  .teaser-topdiv {
    max-height: 420px;
  }

  .teaser-topdiv {
    aspect-ratio: 2 / 3;
  }

  .teaser-preview {
    margin: 75px auto 0 auto !important;
  }

  .teaser-options {
    flex-wrap: unset;
    max-width: unset;
    overflow: auto;
  }

  .record-topdiv p {
    padding: 0px 15px;
  }

  .record-btndiv button {
    padding: 5px 20px;
  }



  .close-btndiv {
    top: 0px;
    left: 0px;
  }

  .heading-div {
    flex-direction: column;
  }

  .heading-divbtn {
    width: 100%;
  }

  .heading-divbtn button {
    padding: 10px 16px;
    width: 100%;
    margin-bottom: 15px;
  }

  .record-container {
    flex-direction: column;
  }

  .record-topdiv {
    max-width: unset;
  }

  .button-section {
    flex-direction: column;
    gap: 20px;
  }

  .button-section1 {
    flex-direction: column;
    gap: 20px;
  }
}


.button-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.retake-btn,
.save-btn {
  padding: 12px 24px;
  /* Set padding for both buttons to ensure they are the same size */
  border-radius: 24px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  width: 100px;
  text-align: center;
}

.retake-btn {
  background-color: #fff;
  /* White background */
  color: #000;
  /* Black text */
  border: 2px solid #000;
  /* Black border */
}

.retake-btn:hover {
  background-color: #f0f0f0;
}

.save-btn {
  background-color: #000;
  /* Black background */
  color: #fff;
  /* White text */
  border: none;
}

.save-btn:hover {
  background-color: #333;
  /* Dark gray on hover */
}



.upload-progress {
  margin-top: 20px;
  height: 20px;
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 8px;
}

.progress-bar {
  width: 0;
  height: 100%;
  background-color: #919191;
  border-radius: 8px;
}

.close {
  float: right;
  font-size: 1.5em;
  cursor: pointer;
}

.video-preview-section {
  margin-top: -25px;
  width: 100%;
}


.modall {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1000;
  /* Sit on top */
  left: 0;
  top: 0;
  padding: 10px;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.5);
  /* Black w/ opacity */
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
}

.upload-content {
  background-color: white;
  /* White background */
  border-radius: 8px;
  /* Rounded corners */
  padding: 20px;
  /* Some padding */
  max-width: 450px;
  width: 100%;
  /* Set a fixed width */
  text-align: center;
  /* Center text */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  align-content: center;
  margin: 30vh auto;
  position: relative;
}

.upload-content p {
  margin: 0;
  padding-bottom: 10px;
  font-size: 15px;
}

.upload-content .close {
  position: absolute !important;
  right: 14px !important;
  top: 5px !important;
}

.upload-progress {
  margin-top: 10px;
  position: relative;
  /* Space above progress bar */
}

.progress-bar {
  background-color: #919191;
  /* Green background */
  height: 20px;
  /* Height of the progress bar */
  width: 0;
  /* Start at 0% width */
  transition: width 0.5s;
  /* Animate width change */
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* Ensure it's on top of other elements */
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.teaser-topdiv {
  width: 100%;
}

.message {
  color: rgb(185, 1, 1);
  /* Change to red for error messages */
  margin-top: 10px;
  font-weight: bold;
}

/* Modal (hidden by default) */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  left: 0;
  top: 0;
  padding: 10px;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 999;
  /* Sit on top */
  justify-content: center;
  align-items: center;
}

/* Modal Content */
.delete-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  width: 90%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Close Button
.close {
  float: right;
  font-size: 24px;
  cursor: pointer;
} */

/* Popup Text */
.delete-content p {
  margin: 10px 0;
  font-size: 16px;
  color: #333;
}

.saved-video {
  width: 100%;
}

/* Popup Buttons */
.popup-buttons {
  margin-top: 20px;
}

.popup-buttons .go-back-btn,
.popup-buttons .delete-btn {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid #333;
  margin: 5px;
  min-width: 140px;
}

.go-back-btn {
  background-color: #fff;
  color: #333;
}

.delete-btn {
  background-color: #000;
  color: #fff;
}

.saved-audio {
  width: 100%;
}

button.delete-btn {
  min-height: 36px;
  padding: 9px 35px;
  font-size: 14px;
  line-height: 1.4;
  border-radius: 36px;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: end;
  margin-left: auto;
}

.button-container button.btn.btn-dark.save-btn {
  display: block;
  min-width: 194px;
  margin-top: 20px;
}

.audio-preview-section {
  width: 100%;
}