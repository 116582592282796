body{
  padding: 0 !important;
}

 .main_gift_shop .teaser-container {
  display: flex;
  gap: 30px;
  padding: 20px;
  border: 1px solid #dee2e6;
  border-radius: 15px;
}

.main_gift_shop .teaser-option.selected {
  border: 2px solid #000;
  box-shadow: inset 0 0 0 4px #fff;
}

.main_gift_shop .teaser-option.selected p {
  margin-top: 15px;
}

.main_gift_shop .teaser-preview1 {
  word-break: break-word;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;
}

.main_gift_shop .teaser-image {
  width: 100px;
  margin-bottom: 20px;
}

.main_gift_shop .teaser-options {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  max-width: 400px;
}

.main_gift_shop .teaser-option {
  width: 100%;
  height: 150px;
  padding: 10px;
  text-align: center;
  border-radius: 8px;
  max-width: 100px;
}

.main_gift_shop .option-image {
  width: 100%;
  object-fit: cover;
  padding-top: 20px;
}

.main_gift_shop .teaser-message {
  letter-spacing: -0.6202px;
  font-family: Graphik Webfont, -apple-system, Helvetica Neue, Droid Sans, Arial, "sans-serif";
  font-size: 27px;
  font-weight: 400;
  line-height: 1.1;
  color: #fff;
}

.main_gift_shop .teaser-details1 {
  letter-spacing: 0.1299px;
  font-family: Graphik Webfont, -apple-system, Helvetica Neue, Droid Sans, Arial, "sans-serif";
  font-size: 16px;
  font-weight: 400;
  line-height: 2.2;
  color: #fff;
}

.main_gift_shop .teaser-message span {
  font-style: italic;
  letter-spacing: -0.6202px;
  font-family: Graphik Webfont, -apple-system, Helvetica Neue, Droid Sans, Arial, "sans-serif";
  font-size: 27px;
  font-weight: 400;
  line-height: 1.1;
  color: #fff;
}

.main_gift_shop .teaser-card1 {
  display: flex;
  gap: 160px;
  align-items: center;
  margin: 50px auto 0px auto;
  justify-content: center;
}

.main_gift_shop .teaser-optiondiv {
  display: flex;
  flex-direction: column;
}

.main_gift_shop img.product-img {
  max-width: 315px;
  border-radius: 15px;
  filter: blur(10px);
  object-fit: cover;
  width: 100%;
  transform: scale(1.1);
}

.main_gift_shop .teaser-topdiv {
  aspect-ratio: 3 / 3;
  align-items: flex-start;
  padding: 18px;
  position: relative;
  overflow: hidden;
  background: #f5f5f5 !important;
  border-radius: 15px;
}

.main_gift_shop .product-imgdiv {
  border-radius: 12px;
  overflow: hidden;
}

.main_gift_shop .teaser-optiondiv p {
  font-family: Graphik Webfont, -apple-system, Helvetica Neue, Droid Sans, Arial, "sans-serif";
  font-size: 12.99px;
  font-weight: 400;
  line-height: 1.4;
  color: #595959;
  margin: 3px 0 10px 0;
}

.main_gift_shop .teaser-options-bottom {
  scrollbar-width: none;
  max-height: 385px !important;
  overflow: auto !important;
}

.main_gift_shop .teaser-lable {
  text-align: center;
  width: 100%;
  margin: 35px 0px 0px 0px;
  font-size: 24px;
}

.main_gift_shop #teaserOptions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 800px;
  margin: 0 auto;
  gap: 10px;
}

.main_gift_shop .teaser-option {
  flex: 1 0 30%;
  max-width: 30%;
  box-sizing: border-box;
  margin: 30px 0px 10px 0px;
  height: 275px !important;
  font-size: 18px;
}

.main_gift_shop .page-width--narrow {
  max-width: 100% !important;

}

.main_gift_shop .rte img {
  border: unset !important;
  height: 250px !important;
}

.gift_video {
  height: 470px;
  width: 100%;  
  padding: 30px 20px 10px;
  margin-top: 10px;
}

.main_gift_shop p#teaserMessage {
  text-align: center;
  letter-spacing: .09505px;
  font-family: Graphik Webfont, -apple-system, Helvetica Neue, Droid Sans, Arial, "sans-serif";
  font-size: 22px;
  font-weight: 500;
  line-height: 1.4;
  padding-top: 30px;
}

.main_gift_shop .img-fluid {
  max-width: 100%;
  height: 250px;
}

.main_gift_shop .color-div {
  height: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 1px solid #ddd;
}

.main_gift_shop .teaser-option p {
  margin-top: 5px;
} 

.lightbox-overlay {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background: #00000063;
  align-content: center;
  margin: 0 auto;
  backdrop-filter: blur(6px);
  padding: 10px;

}

.lightbox-content {
  max-width: 800px;
  width: 100%;
  margin: auto;
  background: white;
  border-radius: 12px;
  position: relative;
  width: 100%;
    padding: 40px 20px 20px;
}

video.video_fram {
  width: 100%;
  margin: 0;
  padding: 0;
}
button.close-lightbox-btn {
  position: absolute;
  top: 0;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 26px;
}

.content_gift p {
  color: white;
}

.unclickable .color-div {
  opacity: 0.5; 
}

.color-div svg {
  cursor: pointer;
}
audio.audio_fram {
  width: 100%;
}
.lightbox-content.audio_popup {
  max-width: 450px;
}


@media screen and (max-width: 767px) {

.main_gift_shop .teaser-card1 {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 20px auto 0px auto;
  justify-content: center;
  flex-direction: column;
}
.content_gift {
  width: 100%;
  padding: 0px 20px;
}
.main_gift_shop .img-fluid {
  max-width: 100%;
  height: 150px;
}

.main_gift_shop .teaser-option {
  max-width: 100% !important;
}

}
